import React, { useState, useEffect } from "react";
import { useTranslation, Link, useI18next } from "gatsby-plugin-react-i18next";
import Layout from "../../components/layout";
import TopHeader from "../../components/top_header";
import Footer from "../../components/footer";
import AnotherQuiz from "../../components/another_quiz";
import ShareBox from "../../components/share_box";
import StepResult from "../../components/results/step";
import ReverseResult from "../../components/results/reverse";
import CreateResult from "../../components/results/create";
import KnockResult from "../../components/results/knock";
import ScanResult from "../../components/results/scan";

import HeaderLogoImage from "../../images/common/header_logo@2x.png";
import HakaseImage from "../../images/common/img_hakase02.gif";
import GrayArrowImage from "../../images/common/arrow-gray.png";

import "../../styles/result.scss";

export default function Result(props) {
  const { t, i18n } = useTranslation();
  const [resultType, setResultType] = useState("");
  const result = props.location.search.match(/&t=([^&]+)&/);
  const currentLanguage = i18n.language;
  const heightNarrowClass = currentLanguage === "en" ? "line_height_narrow" : ""
  const { navigate } = useI18next();

  useEffect(() => {
    if (result) {
      setResultType(result[1]);
    } else {
      setResultType("");
    }
  }, [result]);

  return (
    <Layout>
      <div className="result">
        <TopHeader
          headerLogoImage={HeaderLogoImage}
        />

        <div id="result_information_column">
          <div className="content_full_box">
            <div className="content_box">
              <div id="title_column">
                <div className="inner">
                  <div className="title">{t("mmind.result.title")}</div>
                </div>
              </div>
              <div className="description_box">
                <StepResult
                  hakaseImage={HakaseImage}
                  resultType={resultType === "sp" ? "block" : "none"}
                  heightNarrowClass={heightNarrowClass}
                />
                <ReverseResult
                  hakaseImage={HakaseImage}
                  resultType={resultType === "re" ? "block" : "none"}
                  heightNarrowClass={heightNarrowClass}
                />
                <CreateResult
                  hakaseImage={HakaseImage}
                  resultType={resultType === "ce" ? "block" : "none"}
                  heightNarrowClass={heightNarrowClass}
                />
                <KnockResult
                  hakaseImage={HakaseImage}
                  resultType={resultType === "kk" ? "block" : "none"}
                  heightNarrowClass={heightNarrowClass}
                />
                <ScanResult
                  hakaseImage={HakaseImage}
                  resultType={resultType === "sn" ? "block" : "none"}
                  heightNarrowClass={heightNarrowClass}
                />

                <div id="five_math_mind_column">
                  <div className="big_icon_img">
                    <img
                      alt=""
                      className="img-responsive center-block icon_img right"
                      src={HakaseImage}
                    />
                  </div>
                  <div className="description_box">
                    <h1 className="title">{t("mmind.result.ft_title")}</h1>
                    <div className="description">
                      <p>{t("mmind.result.ft_description")}</p>
                    </div>
                    <button
                      className="btn btn-default btn-block learnmore_button"
                      id="moveToFiveThinkingButton"
                      onClick={(event) => {
                        event.preventDefault();
                        navigate("/mmind/fthinking/");
                      }}
                    >
                      {t("mmind.result.ft_jump_button")}
                    </button>
                  </div>
                  <div className="checkanswer_column_large">
                    <div className="inner">
                      <Link
                        className="moveToCheckAnswerSite"
                        to="/mmind/answers/"
                      >
                        {t("mmind.result.check_answer")}
                        <img
                          alt=""
                          className="img-responsive center-block moveToCheckAnswerSiteImage"
                          src={GrayArrowImage}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {currentLanguage !== "zh" ? <ShareBox /> : ""}

        {currentLanguage === "ja" ? <AnotherQuiz /> : ""}

        <Footer />
      </div>
    </Layout>
  );
}
