import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

import "../styles/bootstrap/bootstrap.custom.css.scss";
import "../styles/reset.css";
import "../styles/general.scss";
import "../styles/fonts.scss";
import "../styles/styles.scss";

export default function Layout({ children }) {
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useI18next();
  const currentLocale = i18n.language;
  const twitterDomain = "mmind.sonyged.com";
  let ogLocale = "";
  let ogUrl = "";

  useEffect(() => {
    window.i18n = i18n;
    const langUrl = window.location.pathname.split("/")[1];
    const langLocal = localStorage.getItem("gatsby-i18next-language");
    if (
      !["en", "ja", "zh"].includes(langUrl) &&
      langLocal &&
      langLocal !== i18n.language
    ) {
      changeLanguage(langLocal);
    }
  });

  switch (currentLocale) {
    case "ja":
      ogLocale = "ja_JP";
      ogUrl = 'https://mmind.sonyged.com/ja/';
      break;
    case "zh":
      ogLocale = "zh_CN";
      ogUrl = 'https://mmind.sonyged.com/zh/';
      break;
    default:
      ogLocale = "en_US";
      ogUrl = 'https://mmind.sonyged.com/';
      break;
  }

  return (
    <div>
      <Helmet>
        <title>{t("mmind.mmind_meta.title")}</title>

        <meta content={t("mmind.mmind_meta.description")} name="description" />
        <meta content={t("layouts.meta.keywords")} name="keywords" />
        <meta content={t("mmind.mmind_meta.title")} itemprop="name" />
        <meta
          content={t("mmind.mmind_meta.description")}
          itemprop="description"
        />

        <meta content={ogLocale} property="og:locale" />
        <meta
          content={ogUrl}
          property="og:url"
        />
        <meta content={t("mmind.mmind_meta.title")} property="og:title" />
        <meta
          content={`https://mmind.sonyged.com/sns_images/mathmind_ogp-facebook_${currentLocale}.png`}
          property="og:image"
        />
        <meta
          content={t("mmind.mmind_meta.description")}
          property="og:description"
        />

        <meta
          content={ogUrl}
          name="twitter:url"
        />
        <meta content={t("mmind.mmind_meta.title")} name="twitter:title" />
        <meta content={twitterDomain} name="twitter:domain" />
        <meta
          content={t("mmind.mmind_meta.description")}
          name="twitter:description"
        />
      </Helmet>
      {children}
    </div>
  );
}
