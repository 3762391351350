import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ScanImage from "../../images/common/img_scan@2x.png"

export default function Scan(props) {
  const { t } = useTranslation();
  return (
    <div id="personality_column" style={{display: props.resultType}}>
      <div className="inner">
        <div className="description scan" id="result_frame">
          <img
            alt="" className="imgResponsive centerBlock icon_img right"
            src={props.hakaseImage}
            />
          <img
            alt={t("scan.title")}
            className="result_image" id="result_image"
            src={ScanImage}
          />
          <h1 className="result_title scan" id="result_title">{t("js.result.type4.title")}</h1>
          <p className="result_subtitle scan" id="result_subtitle">{t("scan.subtitle")}</p>
          <p className={`result_detail dynamic_line_height ${props.heightNarrowClass}`}>{t("scan.detail")}</p>
        </div>
      </div>
    </div>
  )
}
