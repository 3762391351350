import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import FacebookIcon from "../images/common/icon_facebook@2x.png";
import TwitterIcon from "../images/common/icon_twitter@2x.png";

import * as common from "../../static/common";

export default function ShareBox() {
  const { t } = useTranslation();

  return (
    <div id="share_column">
      <div id="share_box">
        <div className="inner">
          <div className="title">{t("mmind.share_box.share_title")}</div>
        </div>
        <div className="sns_buttons">
          <button
            className="btn btn-default social_button"
            id="facebookButton"
            type="button"
            onClick={() => common.didClickDefaultFacebookButton()}
          >
            <p>
              <img
                alt=""
                className="img-responsive center-block icon_img"
                src={FacebookIcon}
              />
              facebook
            </p>
          </button>
          <button
            className="btn btn-default social_button"
            id="twitterButton"
            type="button"
            onClick={() => common.didClickDefaultTwitterButton()}
          >
            <p>
              <img
                alt=""
                className="img-responsive center-block icon_img"
                src={TwitterIcon}
              />
              twitter
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}
