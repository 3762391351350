import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ReverseImage from "../../images/common/img_reverse@2x.png"

export default function Reverse(props) {
  const { t } = useTranslation();
  return (
    <div id="personality_column" style={{display: props.resultType}}>
      <div className="inner">
        <div className="description reverse" id="result_frame">
          <img
            alt="" className="imgResponsive centerBlock icon_img right"
            src={props.hakaseImage}
          />
          <img
            alt={t("reverse.title")}
            className="result_image" id="result_image"
            src={ReverseImage}
          />
          <h1 className="result_title reverse" id="result_title">{t("js.result.type1.title")}</h1>
          <p className="result_subtitle reverse" id="result_subtitle">{t("reverse.subtitle")}</p>
          <p className={`result_detail dynamic_line_height ${props.heightNarrowClass}`}>{t("reverse.detail")}</p>
        </div>
      </div>
    </div>
  )
}
