import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import CreateImage from "../../images/common/img_create@2x.png"

export default function Create(props) {
  const { t } = useTranslation();

  return (
    <div id="personality_column" style={{display: props.resultType}}>
      <div className="inner">
        <div className="description create" id="result_frame">
          <img
            alt="" className="imgResponsive centerBlock icon_img right"
            src={props.hakaseImage}
          />
          <img
            alt={t("create.title")}
            className="result_image" id="result_image"
            src={CreateImage}
          />
          <h1 className="result_title create" id="result_title">{t("js.result.type2.title")}</h1>
          <p className="result_subtitle create" id="result_subtitle">{t("create.subtitle")}</p>
          <p className={`result_detail dynamic_line_height ${props.heightNarrowClass}`}>{t("create.detail")}</p>
        </div>
      </div>
    </div>
  )
}
