/* eslint-disable */

import React, { useState } from "react";
import { Link } from "gatsby-plugin-react-i18next";
import MenuModal from "../pages/menu_modal";

export default function TopHeader(props) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  function closeMenu() {
    setMenuOpen(false);
  }

  function openMenu() {
    setMenuOpen(true);
  }

  return (
    <div>
      <div className="header_img"></div>
      <div className="header_full_box">
        <div className="header_box">
          <div className="header">
            <div className="logo">
              <Link to="/mmind">
                <img src={props.headerLogoImage} alt="Header logo@2x" />
              </Link>
            </div>

            <div className="menu">
              <a onClick={openMenu}>
                <div className="inner">
                  <ul
                    className={`menu-trigger list-inline ${
                      isMenuOpen ? "active" : ""
                    }`}
                  >
                    <li>
                      <span></span>
                      <span></span>
                      <span></span>
                    </li>
                  </ul>
                </div>
              </a>

              <MenuModal isOpen={isMenuOpen} onRequestClose={closeMenu} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
