import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import StepImage from "../../images/common/img_step@2x.png"

export default function Step(props) {
  const { t } = useTranslation();
  return (
    <div id="personality_column" style={{display: props.resultType}}>
      <div className="inner">
        <div className="description step" id="result_frame">
          <img
            alt="" className="imgResponsive centerBlock icon_img right"
            src={props.hakaseImage}
            />
          <img
            alt={t("step.title")}
            className="result_image" id="result_image"
            src={StepImage}
          />
          <h1 className="result_title step" id="result_title">{t("js.result.type0.title")}</h1>
          <p className="result_subtitle step" id="result_subtitle">{t("step.subtitle")}</p>
          <p className={`result_detail dynamic_line_height ${props.heightNarrowClass}`}>{t("step.detail")}</p>
        </div>
      </div>
    </div>
  )
}
