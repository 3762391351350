import React from "react"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

import "../styles/footer.scss";

function Footer(props) {
  const { t } = useTranslation();

  return (
    <div id="footer">
      <div className="footer_full_box">
        <div className="inner">
          <p className="regular_link">
            <Link to="/mmind" id="moveToTopFooter">{t("mm_top_link")}</Link>
          </p>
          <p className="regular_link">
            <Link to="/mmind/fthinking/" id="moveToFiveThinkingFooter">{t("mm_five_type_link")}</Link>
          </p>
          <p className="regular_link">
            <Link to="/mmind/asite/" id="moveToAsiteFooter">{t("mm_about_site")}</Link>
          </p>
        </div>
        <div className="copyright_box">
          <p className="copyright">Contents ©2015-2020 Sony Global Education, Inc.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer;
