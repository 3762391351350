import React from "react";

import GMCBookImage from "../images/common/gmc_book1.jpg";

export default function AnotherQuiz() {
  return (
    <div id="another_quiz_column">
      <div className="content_full_box">
        <div className="content_box">
          <div className="inner">
            <h1 className="title">「5つの思考回路」を鍛える書籍</h1>
            <div className="description_box">
              <div className="text_box">
                <div className="description dynamic_line_height">
                  <p>
                    学校の勉強では身につかない、でも社会に出ると必要とされる、そんな論理的思考力が身につく良問が満載。論理的思考力を5つの思考回路に分類して紙面を構成した画期的なドリル。子どもも解ける、大人も悩む、あなたには論理的思考力があるか!?
                  </p>
                </div>
                <a
                  className="btn btn-default btn-block sign_up_button"
                  href="https://amzn.to/2T9HZ1S"
                  target="_blank"
                  rel="noreferrer"
                >
                  5分で論理的思考力ドリル
                </a>
                <img
                  alt=""
                  border="0"
                  height="1"
                  src="//ir-jp.amazon-adsystem.com/e/ir?t=hakase12309-22&amp;l=ur2&amp;o=9"
                  style={{
                    border: `none !important`,
                    margin: `0px !important`,
                    width: `1`,
                  }}
                />
                <a
                  className="btn btn-default btn-block sign_up_button large"
                  href="https://amzn.to/2HFbiXO"
                  target="_blank"
                  rel="noreferrer"
                >
                  5分で論理的思考力ドリル<br></br>ちょっとやさしめ
                </a>
                <img
                  alt=""
                  border="0"
                  height="1"
                  src="//ir-jp.amazon-adsystem.com/e/ir?t=hakase12309-22&amp;l=ur2&amp;o=9"
                  style={{
                    border: `none !important`,
                    margin: `0px !important`,
                    width: `1`,
                  }}
                />
                <a
                  className="btn btn-default btn-block sign_up_button large"
                  href="https://amzn.to/2CdwhjR"
                  target="_blank"
                  rel="noreferrer"
                >
                  5分で論理的思考ドリル<br></br>ちょっとむずかしめ
                </a>
                <img
                  alt=""
                  border="0"
                  height="1"
                  src="//ir-jp.amazon-adsystem.com/e/ir?t=hakase12309-22&amp;l=ur2&amp;o=9"
                  style={{
                    border: `none !important`,
                    margin: `0px !important`,
                    width: `1`,
                  }}
                />
                <div className="notice">
                  <p>
                    ※Amazon.co.jpアソシエイト・プログラム：株式会社ソニー・グローバルエデュケーションは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイト宣伝プログラムである、Amazonアソシエイト・プログラムの参加者です。
                  </p>
                </div>
              </div>
              <div className="what_is_gmc_box">
                <div className="winner"></div>
              </div>
              <div className="image_box">
                <img
                  alt=""
                  className="img-responsive center-block icon_img book right"
                  src={GMCBookImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
